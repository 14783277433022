<template>
  <div
    class="landing-container"
  >
    <span
      class="text-h2 azure-text mb-10"
    >
      Page Not Found
    </span>
    <span
      class="text-subtitle-1 mb-10"
    >
      We're sorry, we couldn't find the page you were looking for
    </span>
    <v-btn
      dark
      to="/"
      color="var(--eastman-azure)"
    >
      Take me back
    </v-btn>
  </div>
</template>

<script>
export default {
  Name: 'NotFound',
}
</script>

<style>

</style>